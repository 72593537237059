/* -------------------------------------
	Tailwind Initialization
   ------------------------------------- */

	@tailwind base;
	@tailwind components;

/* -------------------------------------
	Main Page Links
   ------------------------------------- */

	.pagelink {
		h2, h3 { transition: color 250ms; }
	}
	.pagelink:hover {
		h2, h3 { color: #ca1640; }
	}

 /* -------------------------------------
	Cookie Consent Overrides
   ------------------------------------- */

	.cc-window {
		font-family: 'Nunito Sans', sans-serif;
		line-height: 1;
		box-shadow: 0 0px 15px -6px rgba(0,0,0,0.4);
	}

	.cc-compliance .cc-btn {
		padding: 0.75em 1.25em;
	}

	.cc-window.cc-banner {
		padding: 0.5em;
		padding-left: 1em;
	}

/* -------------------------------------
	Prose Overrides
   ------------------------------------- */

	.prose {
		max-width: none;

		li {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

/* -------------------------------------
	Bullets
   ------------------------------------- */

	.bullets ul > li {
		position: relative;
		padding-left: 1.75em;
	}

	.bullets ul > li::before {
		content: "";
		position: absolute;
		background-color: #AFAFAF;
		border-radius: 50%;
		width: 0.375em;
		height: 0.375em;
		top: calc(0.875em - 0.45em);
		left: 0.25em;
	}

/* -------------------------------------
	Email sign up
   ------------------------------------- */

	.is-hidden {
		display: none !important;
	}

	@keyframes rotate {
		100% { transform: rotate(360deg); }
	}

	@keyframes dash {
		0% { stroke-dasharray: 1,200; stroke-dashoffset: 0; }
		50% { stroke-dasharray: 89,200; stroke-dashoffset: -35; }
		100% { stroke-dasharray: 89,200; stroke-dashoffset: -124; }
	}

	.loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.loading-spinner {
		width: 50px;
		height: 50px;
	}

	.loading-spinner svg {
		position: relative;
		animation: rotate 2s linear infinite;
		height: 50px;
		width: 50px;
	}

	.loading-spinner circle {
		stroke: #ca1640;
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

/* -------------------------------------
	Game Elements
   ------------------------------------- */

	.game-container{
		.game-page {
			display: none;
		}

		.game-page.current-page {
			display: block;
		}
	}

	.game-pagination {
		.step {
			border-color: #d1d1d1;
			color: #4d4d4d;
		}

		.step.current-step {
			border-color: #ca1640;
			color: #ca1640;
		}
	}

/* -------------------------------------
	Tabs
   ------------------------------------- */

	.label {
		transition: background 0.25s, color 0.25s, width 0.25s;

		.input:focus + & { z-index: 1; }

		.input:checked + & {
			background: #222;
			color: #fafafa;
		}
	}

	.panel {
		.input:checked + .label + & {
			display: block;
		}
	}

/* -------------------------------------
	Modal Style Utility [WIP]
   ------------------------------------- */

	.modal {
		transition: opacity 0.25s ease;
	}

	body.modal-active {
		overflow-x: hidden;
		overflow-y: hidden !important;
	}

	@media (min-width: 768px) {
		body.modal-active {
			overflow-y: visible !important;
		}
	}

/* -------------------------------------
	Force Wrap
   ------------------------------------- */

	.dont-break-out {
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

/* -------------------------------------
	Clean Select
   ------------------------------------- */

	.clean-select,
	.clean-select:active,
	.clean-select:focus {
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-webkit-appearance: none;
		-webkit-border-radius: 0px;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		outline: none;
	}

/* -------------------------------------
	Tailwind Utilities (keep at end)
   ------------------------------------- */

	@tailwind utilities;